import React, { useEffect, useState } from 'react';
import { validation } from '../../../shared/form-validations';
import { textInput, selectField, datePicker } from '../../../shared/form-elements'
import { connect, useDispatch, useSelector } from "react-redux";
import { reduxForm, Field, change  } from "redux-form";
import { Button, Modal } from 'react-bootstrap';
import CloseButton from 'react-bootstrap/CloseButton'
import { TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import moment from 'moment';

function ContentGroupContentForm(props) {
  const { handleSubmit, handleCloseForm, stages, dueDateInitialize, loading} = props

  return (
    <Modal
      show={true}
      onHide={handleCloseForm}
      size={"md"}
    >
      <Modal.Header closeButton>
        <Modal.Title> Add Card </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={ (e) => loading ? null : handleSubmit(e) }>
          <div className="mb-2">
            <Field
              name="name"
              type="text"
              autoFocus
              label="Content"
              component={textInput}
            />
          </div>
          <div className="mb-2">
            <Field
              name="stage_id"
              options={stages}
              label="Select stage"
              blank_label="select"
              component={selectField}
            />
          </div>
          <div className="row data_picker">
            <div className="col-6">
              <div className="mb-2">
                <label style={{fontSize: "14px"}}>Start Date</label>
                <Field
                  name="start_date"
                  component={datePicker}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="mb-2">
                <label style={{fontSize: "14px"}}>Due Date</label>
                <Field
                  name="due_date"
                  component={datePicker}
                  dueDateInitialize={dueDateInitialize}
                  due_date={true}
                />
              </div>
            </div>
          </div>
          <div className="form-buttons">
            <Button
              onClick={handleCloseForm}
              variant="default"
              style={{marginRight: 20}}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={loading}
            >
              Submit
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

ContentGroupContentForm =  reduxForm({
  form: 'content_group_form',
  enableReinitialize : true
})(ContentGroupContentForm);

const mapStateToProps = (state, ownProps) => {
  return {
    loading: state.content_group.content_group_loading
  };
};

export default connect(mapStateToProps, {})(ContentGroupContentForm);
