import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import logoWhite from '../../../images/logo/UPP-Logotype-Landscape-White-Transparent.png';

function LoginInfo(props) {

  return (
    <div className="login-page__info__container vertical-scroll_thin hidden-scroll">
      <img src={ logoWhite } title="Upprovall" alt="Upprovall" height="40" />
      <h1>
        Project Management Tool
      </h1>

      <p>
          Upprovall is a powerful yet simple tool — making complex processes and long production lines as easy as <b>upload, approve, up-next</b>.
      </p>
      <p>
        More things done, less time wasted.
      </p>
      <div
        className="p-3 login-page__account-features"
      >
        <ul className="list-unstyled">
          <li>Free forever account</li>
          <li>No credit card needed</li>
          <li>Access all functionality</li>
          <li>Unlimited stages</li>
          <li>Unlimited cards</li>
          <li>Unlimited user invites</li>
        </ul>
      </div>

      <ul className="list-unstyled login-page__pages">
        <li>Workspaces</li>
        <li>List View</li>
        <li>Board View</li>
        <li>Calendar View</li>
        <li>Approval</li>
        <li>Timestamp</li>
        <li>Revision Count</li>
      </ul>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {  })(LoginInfo);
