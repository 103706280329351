import React, { useEffect, useState, useRef, useContext } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { userActions, work_spaceActions, organizationActions } from "../../../actions";
import { WorkspaceInlineEdit } from '../../../components/WorkspaceInlineEdit.js';
import { Navbar, NavDropdown, Button, Dropdown, Row, Col, Spinner } from "react-bootstrap";
import { adminBaseURL } from "../../../utils/commonUtils";
import { useMediaQuery } from 'react-responsive';
import { history } from '../../../helpers';
import '../../stylesheets/workspace_page-show.scss';
import WorkSpaceEdit from '../WorkSpacePage/edit';
import WorkspaceUsersPopup from '../CollaboratorPage/list';
import { Popup, UserThumbnails, ElementTooltip } from '../../../components';
import { tooltipConstants } from '../../../constants';
import WorkspaceSearchBar from '../../../components/WorkspaceSearchBar';
import { WorkspaceBarContext } from '../../../contexts/WorkspaceBarContext';
import { matchPath } from 'react-router';
import MoreVertival from '../../../images/more-vertical.svg';

function WorkspaceBar(props) {
  const {
          workspace_id,
          workspace_view,
          currentWorkspace,
          can_invite_collaborator,
          can_access_workspace,
          can_access_user_add_invite,
          contentGroupPopUp,
          workspaces,
          loading
  } = props
  const dispatch = useDispatch();
  const workspaceBaseUrl = adminBaseURL("workspaces");
  const isMobileScreen = useMediaQuery({ query: '(max-width: 991px)' });
  const work_space_error = useSelector(state => state.work_space);
  const [view, setView] = useState("list");
  const { workspaceBarRef } = useContext(WorkspaceBarContext)
  const [deletePopup, setDeletePopup] = useState({
    show: false,
    id: null,
  });
  const location = useLocation();
  const params =  matchPath(location.pathname, { path: workspaceBaseUrl+"/:workspace_id", strict: false, exact: false })
  const [workspace_logo_cached, setWorkspaceLogoCached] = useState(false);
  const [workspace_logo, setWorkspaceLogo] = useState();
  const [workspace_dropdown_cached, setWorkspaceDropdownCached] = useState(false);

  const [popupEditForm, setPopUpEditForm] = useState({
    show: false,
    id: null
  });;

  const handleCloseEditForm = () => {
    //setPopUpEditForm({ show: false, id: null });
    window.location.reload();
  }

  const [showWorkspaceUsersPopup, setShowWorkspaceUsersPopup] = useState({
    show: false,
    id: null
  });

  const handleShowWorkspaceUsersPopup = (id) => {
    setShowWorkspaceUsersPopup({ show: true, id: id })
  }

  const handleCloseWorkspaceUsersPopup = () => {
    setShowWorkspaceUsersPopup({ show: false, id: null })
  }

  useEffect(() => {
    if (!!workspace_id) {
      handleClickTab(workspace_id, workspace_view || "list");
    }
  }, [workspace_id]);

  useEffect(() => {

    if(workspace_logo_cached){
      return;
    }

    if (currentWorkspace?.logo?.thumb_url) {
      setWorkspaceLogo(currentWorkspace?.logo.thumb_url);
      setWorkspaceLogoCached(true);
    }

  }, [currentWorkspace?.logo]);

  useEffect(() => {
    if(workspace_dropdown_cached){
      return;
    }
    if(currentWorkspace){
      let serializer_type = 'list'

      dispatch(organizationActions.getWorkspaces(currentWorkspace.organization_id, serializer_type))

      setWorkspaceDropdownCached(true);
    }
  }, [currentWorkspace]);

  const handleClickTab = (workspace_id, view) => {
    let noReload = false
    dispatch(work_spaceActions.setWorkspaceView(workspace_id, view, noReload));
  }

  const handleShowEditForm = (id) => {
    dispatch(work_spaceActions.editWorkSpace(id, setPopUpEditForm));
  }

  const handleShowDeletePopup = (id) => setDeletePopup({ show: true, id });

  const handleDelete = () => {
    dispatch(work_spaceActions.destroyWorkSpace(deletePopup.id));
    setDeletePopup({ show: false, id: null });
  }


  const handleCloseDeletePopup = (handleDelete) => {
    setDeletePopup({ show: false, id: null });
  }

  const handleInlineEdit = (handleOnBlur, values, workspace_id) => {
    dispatch(work_spaceActions.inlineEditWorkspace(handleOnBlur, values, workspace_id));
  }

  const sorted_workspaces = workspaces?.sort((a, b) => a.name.localeCompare(b.name)).filter(w => w.id !== currentWorkspace?.id);

  const handleWorkspaceDropDownToggle = (open) => {
    if(open) {
      let serializer_type = 'list'

      dispatch(organizationActions.getWorkspaces(currentWorkspace.organization_id, serializer_type))
    }
  }

  return (
    <div>
      <Row className={`workspace_page-title py-1 ${ currentWorkspace && !contentGroupPopUp.show ? '' : 'd-none'}`} ref={workspaceBarRef}>
        <Col className="col-auto">
          <div className="workspace-bar__details-wrapper">
            <div className="workspace_title">
              { workspaces && workspaces.length > 0 &&
                <NavDropdown
                  title={
                    <>
                      { workspace_logo &&
                        <img
                          src={workspace_logo}
                          className="workspace_thumbnail_logo"
                        />
                      }
                      <span>
                        <span className="dropdown-toggle__text h4 font-weight-bold mr-3">
                          { currentWorkspace?.name }
                        </span>
                        {
                          sorted_workspaces.length > 1 &&
                          <i className="fa fa-caret-down"></i>
                        }
                      </span>
                    </>
                  }
                  id="basic-nav-dropdozwn"
                  onToggle={handleWorkspaceDropDownToggle}
                  className={`workspace_bar-dropdown`}
                >

                  {
                      sorted_workspaces.map((data, index) => (
                        <NavDropdown.Item
                          key={index}
                          href={`/organizations/${data.organization_id}`+workspaceBaseUrl + "/" + data.id + "/list"}
                          className="text-truncate"
                        >
                          {
                            data.logo && data.logo.thumb_url &&
                            <img
                              src={data.logo.thumb_url}
                              title={data.name}
                              alt={data.name}
                              height={32}
                              className={"mr-3"}
                            />
                          }

                          {data.name}
                        </NavDropdown.Item>
                      ))
                  }
                  { loading && sorted_workspaces.length > 1 &&
                    <div className="spinner-container">
                      <Spinner animation="border" />
                    </div>
                  }
                </NavDropdown>
              }


              &nbsp;

              {currentWorkspace && (currentWorkspace.can_access_update_workspace || currentWorkspace.can_access_destroy_workspace) && (
                <Dropdown className="d-inline-block">
                  <Dropdown.Toggle variant="primary" id="dropdown-basic" className="btn-transparent" title="Options">
                    <img src={MoreVertival}/>
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{right: "auto"}}>
                    {
                      currentWorkspace.can_access_update_workspace &&
                      <Button onClick={() => handleShowEditForm(currentWorkspace.id)} variant="secondary" className="dropdown-item">
                        Settings
                      </Button>
                    }
                    {
                      currentWorkspace.can_access_destroy_workspace &&
                      <Button onClick={() => handleShowDeletePopup(currentWorkspace.id)} variant="secondary" className="dropdown-item">
                        Delete
                      </Button>
                    }
                  </Dropdown.Menu>
                </Dropdown>
              )}

              <WorkspaceSearchBar />
            </div>

            <div className="workspace-bar__users-wrapper" onClick={() => can_access_user_add_invite ? handleShowWorkspaceUsersPopup(currentWorkspace?.id) : null}>
              <UserThumbnails
                users={currentWorkspace?.workspace_users}
                limit={5} tooltip={true}
                disable_users_info={true}
              />
            </div>
            <ElementTooltip
              tooltip_title={'Users'}
              tooltip_text={tooltipConstants.INVITED_USER}
              id={'workspace_bar-user_thumbnails'}
              placement={'auto-end'}
            />
          </div>
        </Col>

        <Col className="col-auto">
          <div className="workspace_page-button-group">
            <Button onClick={() => handleClickTab(currentWorkspace?.id, "list")} className={`px-2 ${workspace_view == 'list' ? 'active' : '' }`} size={"sm"}>List</Button>
            <Button onClick={() => handleClickTab(currentWorkspace?.id, "board")} className={`px-2 ${workspace_view == 'board' ? 'active' : '' }`} size={"sm"}>Board</Button>
            <Button onClick={() => handleClickTab(currentWorkspace?.id, "calendar")} className={`px-2 ${workspace_view == 'calendar' ? 'active' : '' }`} size={"sm"}>Calendar</Button>
          </div>
        </Col>
      </Row>

      {
        showWorkspaceUsersPopup.show &&
        <WorkspaceUsersPopup
          workspaceId={showWorkspaceUsersPopup.id}
          handleClose={handleCloseWorkspaceUsersPopup}
        />
      }
      {
        popupEditForm.show &&
        <WorkSpaceEdit
          workspace_id={popupEditForm.id}
          handleCloseEditForm={handleCloseEditForm}
          work_space_error={work_space_error}
        />
      }

      {
        deletePopup.show &&
        <Popup
          handleClose={handleCloseDeletePopup}
          handleDelete={handleDelete}
        />
      }
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    loading: state.work_space.workspace_dropdown_loading,
    workspaces: state.work_space.work_spaces,
    currentWorkspace: state.work_space.currentWorkSpace,
    workspace_view: state.work_space.workspaceView,
    can_access_workspace: state.work_space.can_access_workspace,
    can_access_user_add_invite: state.work_space.can_access_user_add_invite
  };
};

export default connect(mapStateToProps, { userActions })(WorkspaceBar);
