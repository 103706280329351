import { useState, useRef, useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { IconButton } from "@material-ui/core";
import { dateTimeFormatter } from '../utils/';
import { CircleAddIcon } from './';
import { connect } from "react-redux";

function DateTimeInlineEdit(props) {
  const {
    content_group_date,
    type,
    center,
    addButtonClass,
    buttonType,
    loading
  } = props

  const [ isDisabled, setIsDisabled ] = useState(false);
  const [ active, setActive] = useState(false)
  const [ pickerView, setPickerView ] = useState(null)
  const [ contentGroupDate, setContentGroupDate] = useState(content_group_date ? new Date(content_group_date) : '--')

  const handleClick = (date) => {
    if(isDisabled) return;

    setActive(!active)

    setTimeout(function() {
      handleOnFocus()
    }, 100)
  }

  const handleOnFocus = () => {}

  const handleOnBlur = () => {
    setActive(false)
    setPickerView(null)
  }

  const handleViewChange = (view) => {
    setPickerView(view)
  }

  const handleChange = (datetime) => {
    setContentGroupDate(datetime)
    setActive(false)

    saveValue(datetime)
  }

  const saveValue = (datetime) => {
    if(isDisabled) return;

    let attributeName = type === 'start_date' ? 'start_date' : 'due_date'
    let params = {}
    params[attributeName] = datetime

    props.handleInlineEdit(handleOnBlur, params, setIsDisabled)
  };

  return (
    <div className={` ${center ? "text-center cursor-pointer" : "cursor-pointer"}` }>
      { !active ?
          <div
            className={`inline_date_date ${isDisabled && 'disabled'}`}
            onClick={ () => handleClick() }
          >
            { content_group_date ?
                dateTimeFormatter(content_group_date)
              :
                <div className={addButtonClass}>
                  {
                    buttonType && buttonType === 'text' ?
                      `Add ${type === 'start_date' ? 'start' : 'end' } date`
                    :
                      <CircleAddIcon />
                  }
                </div>
            }
          </div>
        :
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker open={ true }
                            showToolbar={ false }
                            slotProps={{ textField: { variant: 'outlined' } }}
                            label="Select date & time"
                            value={ contentGroupDate }
                            onViewChange={ handleViewChange }
                            onClose={ handleOnBlur }
                            onAccept={ handleChange }
                            slotProps={{
                              textField: {
                                InputProps: {}
                              },
                              actionBar: {
                                actions: ['clear', 'accept']
                              }
                            }}

            />
          </LocalizationProvider>
      }
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    loading: state.content_group.content_group_loading,
  };
};

export default connect(mapStateToProps, { })(DateTimeInlineEdit);
