import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { InfoIcon } from '../';
import Overlay from 'react-bootstrap/Overlay';
import { useState, useRef } from 'react';
import { useDetectOutsideClick } from '../../utils/useDetectOutsideClick';
import CloseButton from 'react-bootstrap/CloseButton'

function ElementTooltip(props) {
  const {
    children,
    tooltip_title,
    tooltip_text,
    placement,
    id,
    className,
    hover
  } = props;
  const target = useRef(null);
  const [show, setShow] = useDetectOutsideClick(target, false);

  const renderTooltip = (props) => (
    <Tooltip id={id} {...props}>
      <div
        className={'mb-1 text-left tooltip-header d-flex justify-content-between'}
      >
        <b>{tooltip_title}</b>
        {
          hover === undefined &&
          <CloseButton title="Close" />
        }
      </div>
      <div
        className={`tooltip-text text-left`}
      >
        { tooltip_text }
      </div>
    </Tooltip>
  );

  const handleInfoClick = (e) => {
    setShow(!show)
  }

  return (
    <>
      {
        hover ?
          <OverlayTrigger
            placement={placement ? placement : 'top-start'}
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
          >
            <span
              ref={target}
              id={id}
              className={`cursor-pointer `+className}
            >
              <InfoIcon height={16} width={16} />
            </span>
          </OverlayTrigger>
        :
          <>
            <span
              ref={target}
              id={id}
              className={`cursor-pointer `+className}
              onClick={(e) => handleInfoClick(e)}
            >
              <InfoIcon height={14} width={14} color={'#2b2f3f'} />
            </span>

            <Overlay
              target={target.current}
              show={show}
              placement={placement ? placement : 'top-start'}
            >
              {({
                placement: _placement,
                arrowProps: _arrowProps,
                show: _show,
                popper: _popper,
                hasDoneInitialMeasure: _hasDoneInitialMeasure,
                ...props
              }) => (
                <div
                  {...props}
                  style={{
                    position: 'absolute',
                    borderRadius: 3,
                    ...props.style,
                  }}
                  className="tooltip"
                >
                  <div
                    className="tooltip-inner"
                  >

                    <div
                      className={'mb-1 text-left tooltip-header d-flex justify-content-between'}
                    >
                      <b>{tooltip_title}</b>
                      {
                        hover === undefined &&
                        <CloseButton
                          title="Close"
                          onClick={() => setShow(false)}
                        />
                      }
                    </div>
                    <div className="tooltip-text text-left">
                      { tooltip_text }
                    </div>
                  </div>

                </div>
              )}
            </Overlay>
          </>
      }
    </>
  );
}

export { ElementTooltip };
