import React, { useEffect, useRef, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { connect,  useDispatch, useSelector } from 'react-redux';
import ReactDom from 'react-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'
import remarkBreaks from 'remark-breaks';
import { contentActions, content_groupActions, commentActions, assigned_userActions, contentLabelActions, work_spaceActions } from '../../../actions';
import {
  Popup,
  LoadSpinner,
  UserThumbnails,
  StageLabel,
  ContentLabel,
  ContentGroupInlineEdit,
  DateTimeInlineEdit,
  ContentInlineEdit,
  StopWatch,
  HiddenContentImageUploader,
  CircleAddIcon,
  ElementTooltip
} from '../../../components';
import { adminBaseURL, buildFormDataFromObject } from '../../../utils/commonUtils';
import ChatMessage from "../ContentPage/chat";
import { Image as ImageHelper, workspaceSizeAlert, getDropdownPosition } from '../../../utils';
import { Button, Dropdown, Row, Col, CloseButton, Image, Spinner } from "react-bootstrap";
import ContentNew from "../ContentPage/new";
import ContentReplace from "../ContentPage/replace";
import {useDetectOutsideClick} from "../../../utils/useDetectOutsideClick";
import StatusChangePopup from "../ContentPage/sections/status_change_popup";
import AttachmentsCarousel from "../ContentPage/sections/attachments_carousel";
import { useMediaQuery } from 'react-responsive'
import ContentLabelPopup from "../ContentLabelPopup/list";
import AssignedUserNew from "../AssignedUserPage/new";
import StartDateIcon from "../../../images/icons/start-date.svg";
import DueDateIcon from "../../../images/icons/due-date.svg";
import LabelIcon from "../../../images/icons/label.svg";
import ParticipantIcon from "../../../images/icons/participant.svg";
import ReplaceIcon from "../../../images/icons/white/replace.svg";
import FileIcon from "../../../images/icons/file.svg";
import DeleteIcon from "../../../images/icons/white/delete.svg";
import CheckIcon from "../../../images/icons/white/check.svg";
import BlackCheckIcon from "../../../images/icons/check.svg";
import RevisionIcon from "../../../images/icons/white/revision.svg";
import SendSubmissionIcon from "../../../images/icons/white/send-submission.svg";
import CancelSubmissionIcon from "../../../images/icons/white/cancel-submission.svg";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import StageIcon from "../../../images/stage.svg";
import { history } from '../../../helpers';
import { tooltipConstants } from '../../../constants';
import { dateTimeFormatter, checkImagesSize } from '../../../utils/';

function ContentList(props) {
  const isMobileScreen = useMediaQuery({ query: '(max-width: 991px)' })
  const ref = useRef();
  const labelDropdownDetectorRef = useRef();
  const {
    content_group_id,
    updated_at,
    workspace_id,
    content_popup_notification,
    currentWorkSpace,
    content_loading,
    content_group_loading
  } = props;
  const currentUser = useSelector(state => state.dashboard.currentUser);
  const location = useLocation();
  const [selected_content_id, setSelectedContentId] = useState(null);
  const [activeTab, setActiveTab] = useState("internal_notes");
  const [approveDropdownSelectedOption, setApproveDropdownSelectedOption] = useState("Approve");
  const filter_options =[
    "all",
    "active",
    "hidden"
  ];
  const dispatch = useDispatch();
  const {
    content,
    contents,
    comments,
    can_add,
    content_group,
    currentContent,
    assigned_users,
    can_assign_user,
    handleCloseContentGroupPopUp,
    handleInlineEdit,
    can_access_submit_approval,
    can_access_accept_revision,
    can_access_reject_revision,
    can_access_accept_request_revision,
    can_access_content, can_access_edit_title,
    can_access_edit_label,
    can_access_add_change_date,
    can_access_content_update,
    can_access_content_create,
    can_access_team_comment,
    can_access_content_versions,
    can_access_content_hide,
    can_access_content_destroy,
    can_access_content_comment,
    can_access_content_group_archive,
    can_access_assigned_user,
    can_access_move_content_board,
    can_access_show_content_details,
    can_sort_attachments,
    contentGroupPopUp,
    setContentGroupPopUp
  } = props
  const workspaceBaseUrl = adminBaseURL("workspaces");
  const Interval_MS = 10000;
  const [contentIndex, setContentIndex] = useState(0);
  const [initialLoad, setInitialLoad] = useState(true);
  const galleryRef = useRef();
  const currentContentGroupRef = useRef();
  const [urlUpdated, setUrlUpdated] = useState(false);
  let latestURL = '';
  const [selectedAttachmentId, setSelectedAttachmentId] = useState(0);
  let { mode } = useParams();
  let modalRef = useRef();
  const [chatFocused, setChatFocused] = useState(false);
  const content_loading_ref = useRef(content_loading);
  const [drpdwnPosition, setDrpdwnPosition] = useState({
    top: 0,
    left: 0
  })

  useEffect(() => {
    //dispatch(content_groupActions.inlineNewContentGroup(workspace_id));
    showContent(setInitialLoad);

    const handleKeyDown = (event) => {
      if (!ref.current) {
         return;
      }

      if (event.key == "Escape") {
        if (labelDropdownDetectorRef.current){
          handleCloseLabelsPopup()
        } else {
          handleCloseContentPopup()
        }
      }
    }

    document.addEventListener("keydown", handleKeyDown);
    ref.current.focus();

    return function cleanup() {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    content_loading_ref.current = content_loading
 }, [content_loading]);

  useEffect(() => {
    const interval = setInterval(() => {
      showContent();
   }, Interval_MS);

   return () => clearInterval(interval);
 }, []);

  useEffect(() =>{
    if(currentWorkSpace && content_group && content_group_id && content_group?.id === parseInt(content_group_id, 10)){
      let new_url = `/organizations/${currentWorkSpace?.organization_id}${workspaceBaseUrl}/${workspace_id}/content/${content_group.id}-${content_group.parameterize_name}`
      if (location.pathname != new_url) {
        window.history.replaceState(null,null, new_url)
        latestURL = new_url;

        history.listen((location, action) => {
          if(latestURL !== '' && location.pathname != latestURL ){
            latestURL = ''
            setContentGroupPopUp({ id: null, show: false });
          }
        });
      }
    }
  }, [content_group, content_group_id])

  const showContent = (setInitialLoad) => {
    if (content_group_id && !content_loading_ref.current) {
      const load_page = true;
      dispatch(contentActions.getContent(workspace_id, content_group_id, load_page, ""));
      const content_id = "first_record";
      dispatch(contentActions.showContent(workspace_id, content_group_id, content_id, "", handleCloseContentPopup, setInitialLoad));
    }
  }

  /*Content Add*/
  const [content_add_form, setContentAddForm] = useState({id: null, show:false});

  const handleCloseContentAddForm = () => {
    setContentAddForm({id: null, show: false});
  }

  const handleOpenContentAddForm = (id) => {
    setContentAddForm({id: id, show: true})
  }

  /*Content Replace*/
  const [content_replace_form, setContentReplaceForm] = useState({id: null, show:false});

  const handleCloseContentReplaceForm = (reload) => {
    if(reload){
      dispatch(contentActions.getContent(workspace_id, content_group_id, ""));
      const content_id = "first_record"
      dispatch(contentActions.showContent(workspace_id, content_group_id, content_id, ""));
    }

    setContentReplaceForm({id: null, show: false});
  }

  const handleOpenContentReplaceForm = (id) => {
    handleCloseContentAddForm();
    dispatch(contentActions.editContent(workspace_id, content_group_id, id, setContentReplaceForm));
  }

  /* Popup Modal*/
  const [filter, setFilter] = useState(filter_options[0]);

  const hideContent = (id) => {
    dispatch(contentActions.hideContent(workspace_id, content_group_id,id));
  }

  const unhideContent = (id) => {
    dispatch(contentActions.unhideContent(workspace_id, content_group_id,id));
  }

  const deleteContent = (id) => {
    dispatch(contentActions.destroyContent(workspace_id, content_group_id,id, currentContent && currentContent.id == id, content_popup_notification));
  }

  /* Preview Panel */
  const [hidePreviewPanel, setHidePreviewPanel] = useState({
    hide: false
  });

  const toggleHidePreviewPanel = () => {
    setHidePreviewPanel({ hide: !hidePreviewPanel.hide })
  }

  /* Popup Modal*/
  const [popup, setPopUp] = useState({
    show: false, id: null,
  });

  const handleClose = () => {
    setPopUp({show: false, id: null});
    setSelectedAttachmentId(0);
  };

  const handleShow = (id) => setPopUp({show: true, id: id});

  const handleDelete = () => {
    deleteContent(popup.id);
    handleClose();
  }

  const handleContent = (content_id) => {
    setSelectedContentId(null);
    if(!content_add_form.show && !content_replace_form.show){
      dispatch(contentActions.showContent(workspace_id, content_group_id, content_id));
    }

  }

  const handleComment = (content_id) => {
    var time = new Date();
    dispatch(commentActions.newComment({description: `Comment for ${currentContent && currentContent.id}`, user: `${currentUser && currentUser.last_name}`, is_user: true, created_at: time, content_id: `${currentContent && currentContent.id}`}));
  }

  /*
    Popup Delete Modal
  */
  const [popupDeleteForm, setPopUpDeleteForm] = useState(false);

  const handleCloseDeleteForm = () => {
    setPopUpDeleteForm(false);
  }

  const handleShowDeleteForm = () => setPopUpDeleteForm(true);

  const handleDeleteContentGroup = () => {
    dispatch(content_groupActions.destroyContentGroup(workspace_id, content_group_id, handleCloseContentPopup));
  }

  /*
    Popup Archive Modal
  */
  const [popupArchiveForm, setPopUpArchiveForm] = useState(false);

  const handleCloseArchiveForm = () => {
    setPopUpArchiveForm(false);
  }

  const handleShowArchiveForm = () => setPopUpArchiveForm(true);

  const handleArchiveContentGroup = () => {
    dispatch(content_groupActions.archiveContentGroup(workspace_id, content_group_id, (content_group && content_group.archived), handleCloseArchiveForm));
  }

  // For comment in Request Revision
  const [description, setDescription]= useState("");

  /*
    Status Change Modal
  */
  const statuses = [
    'request_revision',
    'submit_for_approval',
    'void_submit',
    'approve_content_group',
    'accept_request',
    'reject_request'
  ]

  const [isDisabled, setIsDisabled] = useState(false);
  const [popupStatusChangeForm, setStatusChangeForm] = useState({show: false, id: null, status: ''});

  const handleCloseStatusChangeForm = () => {
    setStatusChangeForm({show: false, id: null, status: ''});
    setDescription("");
    setIsDisabled(false);
  }

  const handleShowStatusChangeForm = (id, status) => setStatusChangeForm({show: true, id: id, status: status});

  let filtered_contents = []
  let active_contents = contents && contents.filter((content) => content.is_active == true);
  let inactive_contents =  contents && contents.filter((content) => content.is_active == false);

  switch(filter){
    case "active":
      filtered_contents = active_contents;
      break;

    case "hidden":
      filtered_contents = inactive_contents;
      break;

    default:
      filtered_contents = contents;
  }

  const handleSort = (option) => {
    setFilter(option);

    let temp_contents = [];

    switch(option){
      case "active":
        temp_contents = active_contents;
        break;

      case "hidden":
        temp_contents = inactive_contents;
        break;

      default:
        temp_contents = contents;
    }


    if(temp_contents && temp_contents.length > 0){
      handleContent(temp_contents[0].id)
    }
  }

  //if (props.loading) {
  //   return <div className="page_loading">Loading..</div>
  //}

  let hasContents = filtered_contents && filtered_contents.length > 0;

  filtered_contents && filtered_contents.sort((a,b) => a.position - b.position);

  /* Content List */

  const handleContentListToggle = () => {
    let content_list = document.getElementById('content_list-container');
    content_list.classList.toggle('toggle');
  }

  const handleContentSelection = (id) => {
    if(selected_content_id){
      setSelectedContentId(null)
    }
    else{
      setSelectedContentId(id)
    }
  }

  const getContentMaxHeight = () => {
    const currentContentContainer = document.getElementById("content_popup_wrapper");

    if (!currentContentContainer) {
      return null;
    } else {
      let containerHeight = currentContentContainer.offsetHeight;
      let portionHeight = containerHeight * 0.6;
      let minimumHeight = 500;

      return Math.max(portionHeight, minimumHeight);
    }
  };

  const [tab, setTab] = useState('contents');
  const handleTab = (rs) => {
    setTab(rs)
  }

  const handleContentInlineEdit = (values, content_id) => {
    let formData = new FormData();
    buildFormDataFromObject(formData, values, "content");
    formData.append("replace", true)
    dispatch(contentActions.updateContentInlineEdit(formData, content_id,workspace_id, content_group_id, content_popup_notification));
  }

  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false)
  const [activePopupHead, setActivePopupHead] = useState(false)

  const activeDropDownPopup = (content_group_id, status) => {
    if(status == 'active_popup') {
      setActivePopupHead(!activePopupHead)
    }else{ setIsActive(!isActive);}
  }

  const closePopupInContent = () => { setActivePopupHead(!activePopupHead)}

  const handleOpenAssignedUserEditPopup = (id, fromList, status) =>{
    dispatch(assigned_userActions.newAssignedUser(workspace_id, id, activeDropDownPopup, status));
  }

  const labelsDropdownRef = useRef(null);
  const [showLabelDropdown, setShowLabelDropdown] = useDetectOutsideClick(labelsDropdownRef, false)
  labelDropdownDetectorRef.current = showLabelDropdown;

  const handleOpenLabelsPopup = (e) => {
    let styles = getDropdownPosition({target: e.target, dropdown: labelsDropdownRef.current, left_padding: 0, top_padding: 0})
    setDrpdwnPosition(styles)
    setShowLabelDropdown(true)
  }

  const handleCloseLabelsPopup = () => {
    setTimeout(() => {
      setShowLabelDropdown(false)
    }, 100)
  }

  const isApprovalActionDisabled = (action) => {
    return content_group.stage.status == 'pending'
  }

  const [ contentAttachmentValue, setContentAttachmentValue] = useState(currentContent && currentContent.content_attachment);

  const [selectContentId, setSelectContentId] = useState(currentContent && currentContent.id)
  useEffect(() => {
    let filter_data = filtered_contents && filtered_contents.filter((item, index) => index === contentIndex ).map(({id}) => (id));
    if (!!filter_data) {
      setSelectContentId(filter_data[0])
    }
  }, [currentContent])

  const _onSlide = (e) => {
    let filter_data = filtered_contents && filtered_contents.filter((item, index) => index === e ).map(({id}) => (id));
    if (!!filter_data) {
      setSelectContentId(filter_data[0]);

      if (filter_data[0] !== selectedAttachmentId){
        setSelectedAttachmentId(0);
      }
    }
    setContentIndex(e);
  }

  const addFile = e => {
    let formData = new FormData();
    buildFormDataFromObject(formData, { attachment: e.target.files}, "content");

    dispatch(contentActions.createContent(formData, workspace_id, content_group_id, content_popup_notification ));
  }

  const handleFileChange = e => {
    if(selectedAttachmentId === 0 ) return;

    let limitExceeded = checkImagesSize([e.target.files[0]], currentWorkSpace?.personal);

    if(!limitExceeded && e.target.files.length > 0){
      workspaceSizeAlert(currentWorkSpace);
      setContentAttachmentValue(URL.createObjectURL(e.target.files[0]));
      handleContentInlineEdit({ attachment: e.target.files[0] }, selectedAttachmentId)
    }

    e.target.value = '';
  }

  const hiddenFileInput = useRef(null);
  const handleFileUploadClick = event => hiddenFileInput.current.click();

  let contents_in_slider = filtered_contents && filtered_contents.map(item => {

    return {
      id: item.id,
      fullscreen: item.content_attachment,
      original: item.original && can_access_submit_approval ? item.original.url : item.content_attachment,
      thumbnail: item.small ? item.small.url : item.content_attachment
    }
  })

  const renderContentGroupSettings = () => {
    if (!(content_group && content_group.can_crud_content_group && (can_access_content_group_archive))) return;

    return (
      <div className="content-group_settings">
        <Dropdown>
          <Dropdown.Toggle variant="primary" id="dropdown-basic" className="" title="Options" style={{padding: '0px'}}>
            <i className="fa fa-cog" aria-hidden="true"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu style={{right: "auto"}} key={'up'}>
            {can_access_content_group_archive &&
              <Button variant="secondary" className="dropdown-item" onClick={() => handleShowArchiveForm()}>{content_group.archived ? "Unarchive" : "Archive"}</Button>
            }
            {content_group.can_crud_content_group &&
              <>
                <Dropdown.Divider />
                <Button variant="secondary" className="dropdown-item" onClick={() => handleShowDeleteForm(true)}>Delete</Button>
              </>
            }
          </Dropdown.Menu>
        </Dropdown>
      </div>
    )
  }

  const addFileInput = useRef(null);
  const handleFileAddClick = event => addFileInput.current.click();

  const handleContentClick = (e) => {
    let currentItem = galleryRef.current.props.items[galleryRef.current.getCurrentIndex()]

    if (currentItem){
      window.open(currentItem.original, '_blank');
    }
  }

  const stageParentRef = useRef(null);

  const handleCloseContentPopup = () =>{
    let previous_url = sessionStorage.getItem("previous_url");
    if(previous_url){
      window.history.replaceState(null,null, previous_url)
    }

    dispatch(contentActions.removeCurrentContentGroup());
    handleCloseContentGroupPopUp();
  }

  const hiddenAddFileInput = useRef(null);

  const handleDeleteLabel = (label_id, e) => {
    e.stopPropagation();

    let requestParams = { label_id: label_id, assigned: false }
    let callback = () => {}

    dispatch(content_groupActions.assignContentLabelToContentGroup(workspace_id, content_group_id, requestParams, content_popup_notification, callback))
  }

  return (
    <div
      ref={ref}
      className={`px-0 container-fluid container-page_container ${content_popup_notification} ${isMobileScreen ? 'mobile_container_page' : "window"}`}
      style={{zIndex: 1024}}
    >
      <div className={activePopupHead ? 'overlay' : ''} onClick={activePopupHead ? () => closePopupInContent() : null} style={{zIndex: 1024}}> </div>
      {props.assigned_user_loading && <LoadSpinner />}
      <CloseButton variant="white" onClick={() => handleCloseContentPopup()} title="Close" />

      {
        content_group && !initialLoad &&
        <div
          className={`container-page_modal-holder ${isMobileScreen ? 'mobile_content_popup' : 'window_content_popup'}`}
          ref={modalRef}
        >
          <div style={{display: isMobileScreen ? 'block' : 'none'}}>
            <ul className="list-inline d-flex justify-content-center custom_tabs mt-2">
              <li className={tab === "contents" ? 'tab_active' : null} onClick={ () => handleTab("contents")}>Contents</li>
              <li className={tab === "comments" ? 'tab_active' : null} onClick={ () => handleTab("comments")}>Comments</li>
            </ul>
          </div>

          { isMobileScreen ? null : renderContentGroupSettings()  }
          <div className="h-100">
            <Row style={{ height: "100%", alignItems: "flex-start" }}>
              <Col
                lg={12}
                className="pb-0 h-100"
                style={{
                  display: isMobileScreen ? tab === "contents" ? 'block' : 'none' : 'block',
                  position: 'relative'
                }}
              >
                <div id="content_popup_wrapper" className="flex_container h-100">
                  <div className="current_content_group-container h-100">
                    <div className="current_content_group-container-grid flex-sm-row flex-column">
                      <div className="content__details-sidebar">
                        <div
                          className={can_access_show_content_details ? `h-100 d-flex flex-column ${ !isMobileScreen && 'border-right' } content__details-sidebar_list vertical-scroll_thin hidden-scroll` : 'd-none'}
                          style={{flex: 0, minWidth: 220}}
                        >
                          <div className="d-flex flex-column">
                            { false && // Not implemented
                              <>
                                <div className="content__details-detail">
                                  <div className="detail done-box">
                                    <div className="form-check">
                                      <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                      <label className="form-check-label mt-0" htmlFor="flexCheckDefault">
                                        DONE
                                      </label>
                                    </div>
                                  </div>
                                </div>

                                <hr/>
                              </>
                            }
                          </div>

                          <div className="content__details-detail mb-2">
                            <div>
                              <label className="mb-2">
                                <img src={ParticipantIcon} className="attribute-icon" />
                                Participants

                                <ElementTooltip
                                  tooltip_title={'Participants'}
                                  tooltip_text={tooltipConstants.LABEL_PARTICIPANTS}
                                  id={'content-popup_label_participants'}
                                  className={'ml-2'}
                                  placement={'auto'}
                                />
                              </label>



                              { (assigned_users && assigned_users.length > 0) ?
                                <div className={`detail bg-transparent ${!can_access_assigned_user && 'no_access' }`}>
                                  <UserThumbnails
                                    users={assigned_users}
                                    className="d-block"
                                    limit={8}
                                    openPopup={can_access_assigned_user ? () => handleOpenAssignedUserEditPopup(content_group.id, false, 'active_body') : null }
                                    openUserSummary={true}
                                    workspace_id={workspace_id}
                                    organization_id={currentWorkSpace?.organization_id}
                                  />
                                </div>
                                :
                                <div className={`detail ${can_access_assigned_user ? '' : 'bg-transparent'}`} onClick={ can_access_assigned_user ? () => handleOpenAssignedUserEditPopup(content_group.id, false) : null} style={{cursor: 'pointer'}}>
                                  {can_access_assigned_user ?
                                      <div className="d-flex align-items-center cursor-pointer">
                                        <div className="bg-white p-1">
                                          Add Participant...
                                        </div>
                                      </div>
                                     :
                                      "-"
                                  }
                                </div>
                              }
                              <nav
                                ref={dropdownRef}
                                className={`assigned_user_list ${isActive ? "active" : "inactive"}`}
                                id="new_assigned_user_list"
                              >
                                <AssignedUserNew
                                  workspace_id={workspace_id}
                                  content_group_id={content_group.id}
                                  closeDropDownPopup={activeDropDownPopup}
                                  fromList={false}
                                  content_popup_notification={content_popup_notification}
                                />
                              </nav>
                            </div>
                          </div>
                          <div className="content__details-detail position-relative mb-2">
                            <label className="mb-2">
                              <img src={LabelIcon} height="14" className="attribute-icon" />
                              Labels
                            </label>
                            { can_access_content_update ?
                                <>
                                  <div className={`detail ${content_group.labels.length > 0 ? 'btn-transparent' : null}`}>
                                    <div onClick={handleOpenLabelsPopup}>
                                      { content_group.labels && content_group.labels.length > 0 ?
                                          content_group.labels.map((contentLabel, index) => (
                                            <ContentLabel
                                              id={contentLabel.id}
                                              name={contentLabel.name}
                                              color_hex={contentLabel.color_hex}
                                              editable={can_access_edit_label}
                                              key={index}
                                              handleDelete={handleDeleteLabel}
                                              custom_close_button_bg_color={"#ededed"}
                                            />
                                          ))
                                        :
                                          <div className="d-flex align-items-center cursor-pointer">
                                            <div className="bg-white p-1">
                                              Add Label
                                            </div>
                                          </div>
                                      }
                                    </div>
                                  </div>
                                </>
                              :
                                <div className={`detail ${!can_access_edit_label || content_group.labels.length > 0 ? 'bg-transparent' : null}`}>
                                  { content_group.labels && content_group.labels.length > 0 ?
                                      content_group.labels.map((contentLabel, index) => (
                                        <ContentLabel editable={false} name={contentLabel.name} color_hex={contentLabel.color_hex} key={index} />
                                      ))
                                    :
                                      "-"
                                  }

                                </div>
                            }
                          </div>

                          <div className="d-flex flex-row flex-sm-column justify-content-between">
                            <div className="content__details-detail mb-2">
                              <label className="mb-2">
                                <img src={StartDateIcon} height="14" className="attribute-icon" />
                                Start Date
                              </label>

                              {can_access_add_change_date ?

                                  <div className={`detail bg-white`}>
                                    <DateTimeInlineEdit
                                      content_group_date={content_group && content_group.start_date}
                                      type="start_date"
                                      handleInlineEdit={(handleOnBlur, start_date, setIsDisabled) => handleInlineEdit(handleOnBlur, start_date, content_group?.id, setIsDisabled)}
                                      addButtonClass={"bg-white p-1"}
                                      buttonType={'text'}
                                    />
                                    <i className="fa fa-caret-down" aria-hidden="true"></i>
                                  </div>
                                :
                                  <div className={`detail bg-transparent`}>
                                    {
                                      ( content_group.start_date ?
                                          dateTimeFormatter(content_group.start_date)
                                        :
                                          '-'
                                      )
                                    }
                                  </div>

                              }

                            </div>

                            <div className="content__details-detail mb-2">
                              <label className="mb-2">
                                <img src={DueDateIcon} height="14" className="attribute-icon" />
                                Due Date

                                <ElementTooltip
                                  tooltip_title={'Due Date'}
                                  tooltip_text={tooltipConstants.LABEL_DUE_DATE}
                                  id={'content_popup-label-due_date'}
                                  className={'ml-2'}
                                />
                              </label>

                              {can_access_add_change_date ?
                                  <div className={`detail bg-white `}>
                                    <DateTimeInlineEdit
                                      content_group_date={content_group && content_group.due_date}
                                      type="due_date"
                                      handleInlineEdit={(handleOnBlur, due_date, setIsDisabled) => handleInlineEdit(handleOnBlur, due_date, content_group?.id, setIsDisabled)}
                                      addButtonClass={"bg-white p-1"}
                                      buttonType={'text'}
                                    />

                                    <i className="fa fa-caret-down" aria-hidden="true"></i>
                                  </div>

                                :
                                  <div className={`detail bg-transparent`}>
                                    {
                                      ( content_group.due_date ?
                                          dateTimeFormatter(content_group.due_date)
                                        :
                                        '-'
                                      )
                                    }
                                  </div>
                              }
                            </div>
                          </div>

                          <div className="content__details-detail d-flex mt-2 d-sm-block justify-content-between mb-2">
                            <label className="mt-0 mb-2">
                              <img src={StageIcon} height="14" className="attribute-icon" />
                              Stage
                            </label>

                            <div className={`detail ${ can_access_move_content_board ? 'bg-white' : 'bg-transparent' }`} ref={stageParentRef}>
                              <StageLabel
                                name={ content_group && content_group.stage_name }
                                can_access_edit_title={ can_access_move_content_board }
                                stages={ content_group.allowed_stages }
                                stageParentRef={stageParentRef}
                                scrollable={true}
                                alignment={'left'}
                                handleInlineEdit={(handleOnBlur, stage_id) => handleInlineEdit(handleOnBlur, stage_id, content_group.id,)}
                              />

                              {
                                can_access_move_content_board &&
                                <i className="fa fa-caret-down" aria-hidden="true"></i>
                              }
                            </div>
                          </div>

                          <div className="d-flex flex-row flex-sm-column justify-content-between">
                            <div className="content__details-detail mb-2">
                              <label className="mb-2">
                                <img src={DueDateIcon} height="14" className="attribute-icon" />
                                Revision Count
                                <ElementTooltip
                                  tooltip_title={'Revision Count'}
                                  tooltip_text={tooltipConstants.LABEL_REVISION_COUNT}
                                  id={'content_popup-label-revision_count'}
                                  className={'ml-2'}
                                />
                              </label>

                              <div className="detail bg-transparent">
                                {
                                  content_group.revision_count
                                }
                              </div>
                            </div>
                            <div className="content__details-detail mb-2">
                              <label className="mb-2">
                                <img src={DueDateIcon} height="14" className="attribute-icon" />
                                Time Spent

                                <ElementTooltip
                                  tooltip_title={'Time Spent'}
                                  tooltip_text={tooltipConstants.LABEL_TIME_TRACK}
                                  id={'content_popup-label-time_spent'}
                                  className={'ml-2'}
                                />
                              </label>

                              <div className="detail bg-transparent">
                                <StopWatch
                                  initialTime={content_group.timestamp_info?.total_seconds}
                                  initialStartDate={content_group.timestamp_info?.latest_start}
                                  isActive={content_group.stage?.enable_timestamp && !content_group.stage?.approved}
                                />
                              </div>
                            </div>
                          </div>

                          { isMobileScreen ? renderContentGroupSettings() : null  }
                        </div>
                      </div>
                      <div className="contents-container mx-2 mt-2 vertical-scroll_thin" id={!can_add ? "other_roles" : null}>
                        <div id="current-container">
                          { contents && contents.length > 1 &&
                            <Button onClick={ () => toggleHidePreviewPanel() } className="content-list__toggle btn btn-success btn-sm mb-1 d-none" style={{marginLeft: (hidePreviewPanel.hide ? "0" : "-2.5rem")}}>
                              { !hidePreviewPanel.hide &&
                                <i className="fa fa-arrow-left" aria-hidden="true"></i>
                              }
                              { hidePreviewPanel.hide &&
                                <i className="fa fa-arrow-right" aria-hidden="true"></i>
                              }
                            </Button>
                          }

                          <div>
                            <div className="d-flex mb-4">
                              {
                                currentWorkSpace &&
                                <>
                                  { currentWorkSpace.logo &&
                                    <div className="d-flex align-items-center">
                                      <Image src={currentWorkSpace.logo.thumb_url} style={{ maxWidth: 50, maxHeight: 40, float: "left", margin: "0 1rem 0 0" }} rounded/>
                                    </div>
                                  }

                                  <div className="w-100">
                                    <ContentGroupInlineEdit
                                      content_group_data={content_group.name}
                                      edit_type="content_group_title"
                                      can_access_content_update={can_access_content_update}
                                      workspace_logo={currentWorkSpace?.logo}
                                      handleInlineEdit={(handleOnBlur, name) => handleInlineEdit(handleOnBlur, name, content_group.id,)}
                                    />

                                    {
                                      !can_access_show_content_details &&

                                      <div style={{height: '0.8em'}} className="d-flex">
                                        <small
                                          style={{fontSize: '0.8em'}}
                                        >
                                          in
                                          <b> { content_group?.stage_name } </b>
                                          stage
                                          due on
                                          <b> {( content_group?.due_date ?
                                                  dateTimeFormatter(content_group.due_date)
                                                :
                                                  '-'
                                              ) }
                                          </b>
                                        </small>
                                      </div>
                                    }
                                  </div>
                                </>
                              }
                            </div>
                            {
                              !can_access_show_content_details &&

                              <div className="mt-2 mb-2">
                                {
                                  content_group?.labels?.map((contentLabel, index) => (
                                    <ContentLabel
                                      name={contentLabel.name}
                                      color_hex={contentLabel.color_hex}
                                      editable={false}
                                      key={index}
                                    />
                                  ))
                                }
                              </div>
                            }

                            { content_replace_form.show &&
                              <ContentReplace
                                handleClose={handleCloseContentReplaceForm}
                                content_id={content_replace_form.id}
                                content_group_id={content_group && content_group.id}
                                hasContents={hasContents}
                                workspace_id={workspace_id}
                                content_popup_notification={content_popup_notification}
                              />
                            }
                            <div style={{position: "relative" }}>
                              {contents && contents.length > 0 &&
                                !hidePreviewPanel.hide &&
                                  <div>
                                    {
                                      content_group_loading || content_loading &&
                                      <div className="clearfix">
                                        <div className="spinner-container">
                                          <Spinner animation="border" />
                                        </div>
                                      </div>
                                    }
                                    <ImageGallery
                                      items={contents_in_slider}
                                      showPlayButton={false}
                                      showFullscreenButton={false}
                                      onSlide={_onSlide}
                                      startIndex={contentIndex}
                                      showThumbnails={false}
                                      lazyLoad={false}
                                      ref={galleryRef}
                                      showNav={false}
                                      onClick={handleContentClick}
                                      disableKeyDown={chatFocused}
                                    />
                                    <div style={{ position: 'relative' }}>
                                      <AttachmentsCarousel
                                        attachments={ filtered_contents }
                                        workspace_id={ workspace_id }
                                        content_group_id={ content_group_id }
                                        updated_at={ updated_at }
                                        isDragDisabled={ !can_sort_attachments }
                                        selected_id={selectContentId}
                                        galleryRef={galleryRef}
                                        selectedAttachmentId={selectedAttachmentId}
                                        setSelectedAttachmentId={setSelectedAttachmentId}
                                        can_access_content_create={ can_access_content_create }
                                        content_popup_notification={content_popup_notification}
                                      />
                                    </div>
                                  </div>
                               }
                              { hasContents &&
                                <div>
                                  <div
                                    className="chatbtn-group content-group__toolbar-buttons mt-10"
                                  >

                                    <input type="file" onChange={handleFileChange} ref={hiddenFileInput} hidden/>

                                    { selectedAttachmentId !== 0 && can_access_content_update &&
                                      <Button onClick={handleFileUploadClick} className="d-flex btn btn-default mb-1" data-toggle="tooltip" title="Replace File" variant="">
                                        <img src={ReplaceIcon} height="14" />
                                      </Button>
                                    }

                                    {selectedAttachmentId !== 0 && can_access_content_destroy &&
                                      <Button onClick={() => handleShow(selectedAttachmentId)} className="px-1 btn btn-default d-flex contant-a custom-tooltip" data-toggle="tooltip" title="Delete File" variant={""}>
                                        <img src={DeleteIcon} height="14" />
                                      </Button>
                                    }

                                    {currentContent && can_access_content_hide &&(
                                      currentContent && currentContent.is_active ?
                                        <Button onClick={() => hideContent(currentContent && currentContent.id)} className="btn btn-default d-none" variant="">
                                          <img src={ImageHelper.Hide.default} alt="no-image" className="mr-1"/> Hide
                                        </Button>
                                      :
                                        <Button onClick={() => unhideContent(currentContent && currentContent.id)} className="btn btn-default d-none" variant="">
                                        <img src={ImageHelper.Unhide.default} alt="no-image" className="mr-1"/> Unhide
                                        </Button>
                                    )}

                                    {can_access_content_comment &&
                                      <Button className={selected_content_id ? "btn btn-default mr-2 active d-none" : "btn btn-default d-none"} variant="" onClick={() => handleContentSelection(currentContent.id)}>
                                        + Comment
                                      </Button>
                                    }
                                  </div>
                                </div>
                              }

                              <div className="description-section">
                                {can_access_content_update ?
                                  <>
                                    {
                                      !hasContents &&
                                      <>
                                        { content_loading ?
                                            <div className="clearfix">
                                              <div className="spinner-container">
                                                <Spinner animation="border" />
                                              </div>
                                            </div>
                                          :
                                            <div className="empty-content_container">
                                              <HiddenContentImageUploader
                                                fileInputRef={ hiddenAddFileInput }
                                                workspace_id={ workspace_id }
                                                workspace={ currentWorkSpace }
                                                content_group_id={ content_group_id }
                                                content_popup_notification={ content_popup_notification }
                                              />
                                              <div className="file_upload_p mb-2" onClick={(e) => hiddenAddFileInput.current.click()}>
                                                <div
                                                  className="mr-2"
                                                >
                                                  <CircleAddIcon color={"#8C8B9A"} />
                                                </div>
                                                Add File
                                              </div>
                                            </div>
                                        }
                                      </>
                                    }
                                    <ContentInlineEdit
                                      inline_type="content_description"
                                      can_edit={can_add}
                                      handleContentInlineEdit={(description) => handleInlineEdit(() => null, description, content_group.id)}
                                      content_data={content_group.description}
                                      hasContents={hasContents}
                                      workspace_id={workspace_id}
                                      content_group_id={content_group_id}
                                      content_popup_notification={content_popup_notification}
                                    />
                                  </>
                                  :
                                  <div className={selected_content_id ? "content-description active-description" : "content-description"}>
                                    <ReactMarkdown
                                      children={content_group.description}
                                      remarkPlugins={[remarkGfm, remarkBreaks]}
                                      components={{
                                        a: props => {
                                          return (
                                            <a href={props.href} className="link_color" target="_blank">{props.children}</a>
                                          )
                                        }
                                      }}
                                    />
                                  </div>
                                }
                              </div>
                              </div>
                            </div>
                          </div>
                      </div>

                      {
                        !isMobileScreen &&
                        <div className="">
                          <div id="chat_message-container" style={{ flex: "1 0" }} className="px-3 border-left h-100">
                            <ChatMessage
                              content_group_id={content_group_id}
                              workspace_id={workspace_id}
                              selected_content_id = {selected_content_id}
                              setSelectedContentId = {setSelectedContentId}
                              activeTab = { activeTab }
                              setActiveTab = { setActiveTab }
                              setChatFocused={ setChatFocused }
                            />
                          </div>
                        </div>
                      }

                    </div>
                  </div>
                </div>
              </Col>

              {
                isMobileScreen &&
                <Col className="" style={{display:  tab == "comments" ? 'block' : 'none' }}>
                  <div id="chat_message-container" style={{ flex: "1 0" }} className="px-3">
                    <ChatMessage
                      content_group_id={content_group_id}
                      workspace_id={workspace_id}
                      selected_content_id = {selected_content_id}
                      setSelectedContentId = {setSelectedContentId}
                      activeTab = { activeTab }
                      setActiveTab = { setActiveTab }
                      setChatFocused={ setChatFocused }
                    />
                  </div>
                </Col>
              }

            </Row>
          </div>
        </div>
      }

        {/* Contents */}
        {popup.show &&
          <Popup
            handleClose={handleClose}
            handleDelete={handleDelete}
          />
        }

        {/* Content Groups */}
        {popupDeleteForm &&
          <Popup
            handleClose={handleCloseDeleteForm}
            handleDelete={handleDeleteContentGroup}
          />
        }

        {popupArchiveForm &&
          <Popup
            handleClose={handleCloseArchiveForm}
            handleDelete={handleArchiveContentGroup}
            display_text={content_group && content_group.archived ? "Are you sure you want to unarchive?" : "Are you sure you want to archive?"}
          />
        }

        <nav
          className={`content-label-popup vertical-scroll_thin ${showLabelDropdown ? 'active' : ''} `}
          ref={labelsDropdownRef}
          style={drpdwnPosition}
        >
          <ContentLabelPopup
            labels={ content_group?.labels }
            workspaceId={ workspace_id }
            contentGroupId={ content_group?.id }
            handleClose={ handleCloseLabelsPopup }
            content_popup_notification={ content_popup_notification }
          />
        </nav>
      </div>
  );
}

const mapStateToProps = (state) => {
  return {
    content_loading: state.content.content_loading,
    contents: state.content.contents,
    can_add: state.content.can_add,
    content_group: state.content.currentContentGroup,
    currentContentGroup: state.content.currentContentGroup,
    content_group_loading: state.content_group.content_group_loading,
    currentContent: state.content.currentContent,
    assigned_users: state.content.assigned_users,
    can_assign_user: state.content.can_assign_user,
    can_access_submit_approval: state.content.can_access_submit_approval,
    can_access_accept_revision: state.content.can_access_accept_revision,
    can_access_reject_revision: state.content.can_access_reject_revision,
    can_access_accept_request_revision: state.content.can_access_accept_request_revision,
    can_access_add_change_date: state.content.can_access_add_change_date,
    can_access_content_update: state.content.can_access_content_update,
    can_access_content_create: state.content.can_access_content_create,
    can_access_team_comment: state.content.can_access_team_comment,
    can_access_content_versions: state.content.can_access_content_versions,
    can_access_content_hide: state.content.can_access_content_hide,
    can_access_content_destroy: state.content.can_access_content_destroy,
    can_access_content_comment: state.content.can_access_content_comment,
    can_access_content_group_archive: state.content.can_access_content_group_archive,
    can_access_assigned_user: state.content.can_access_assigned_user,
    can_access_move_content_board: state.content.can_access_move_content_board,
    can_access_show_content_details: state.content.can_access_show_content_details,
    can_sort_attachments: state.content.can_sort_attachments,
    can_access_edit_label: state.content.can_access_edit_label,
    assigned_user_loading: state.assigned_user.assigned_user_loading,
    currentWorkSpace: state.content.currentWorkSpace
  };
};

export default connect(mapStateToProps, { contentActions })(ContentList);
