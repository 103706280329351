import React, { useEffect, useState, useRef, useCallback, useContext } from "react";
import { renderToString } from 'react-dom/server';
import { connect, useDispatch, useSelector } from "react-redux";
import { Button, Row, Col } from "react-bootstrap";
import { alertActions, stageActions, content_groupActions, contentLabelActions } from '../../../actions';
import Calendar from '@toast-ui/react-calendar';
import '@toast-ui/calendar/dist/toastui-calendar.css';
import { ContentLabel } from '../../../components';
import ContentGroupContent  from '../WorkSpacePage/contentGroupForm';
import { change, reset } from "redux-form";
import { adminBaseURL } from "../../../utils/commonUtils";
import { useIsVisible } from '../../../utils/useIsVisible';
import { dateTimeFormatter } from '../../../utils';
import { WorkspaceShowContext } from "../../../contexts/WorkspaceShowContext";
import { useDetectOutsideClick } from "../../../utils/useDetectOutsideClick";
import ContentLabelPopup from '../ContentLabelPopup/list';

function WorkspaceCalendar(props) {
  const dispatch = useDispatch();
  const {
    workspace_id,
    stages,
    projects,
    can_access_move_content_calendar,
    created_content_group,
    destroyed_content_group,
    updated_content_group,
    currentSearchText,
    can_access_edit_label,
    schedule,
    workspace_view,
    content_group_loading
  } = props
  const workspaceBaseUrl = adminBaseURL("workspaces");
  const [scheduleList, setScheduleList] = useState([]);
  const custom_close_button_id_text = 'calendar_label-';

  const {
    can_access_content,
    handleOpenContentGroupPopUp,
    handleOpenLabelDrpdwn,
    drpdwnPosition,
    labelDrpdwnValues,
    setLabelDrpdwnValues,
    loadTracker,
    query
  } = useContext(WorkspaceShowContext);

  const getColor =(color) => {
    if (color){
      var rgb = color.replace('#', '');

      return rgb;
    } else {
      return null
    }
  }

  const handleCloseLabelDrpdwn = () => {
    setShowLabelDrpdwn((prevState) => false);
    setLabelDrpdwnValues({
      content_group_id: null,
      labels: []
    })
  };

  let labelDrpdwnContainerRef = useRef();
  // The useDetectOutsideClick detects two events when clicking at calendar event labels preventing from opening popup
  const ignoredClickCount = 1;
  let [showLabelDrpdwn, setShowLabelDrpdwn] = useDetectOutsideClick(labelDrpdwnContainerRef, false, ignoredClickCount);

  let calendarRef = useRef(null);
  let calendarTabRef = useRef()
  const isVisible = useIsVisible(calendarTabRef);
  const initialLoaded = useRef(false);
  const [date, setDate] = useState(Date().toLocaleString());

  const toLocaleDate = (date_param) =>{
    var date = new Date(date_param)
    var userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() - userTimezoneOffset);
  }

  const addSchedule = (new_schedule = []) => {
    let new_schedule_ids = new_schedule.map((s) => s.id);
    let old_schedule_list = scheduleList
    let new_schedule_list = [...scheduleList.filter(s => !new_schedule_ids.includes(s.id)), ...new_schedule]
    setScheduleList(new_schedule_list)

    const old_array = old_schedule_list.map((o) => removeKeys(o));
    const new_array = new_schedule_list.map((o) => removeKeys(o));

    if(JSON.stringify(old_array) !== JSON.stringify(new_array)){
      closeOverlayPopup();
    }
  }

  const removeKeys = (object) => {
    const { assigned_users, preview, first_record, ...rest } = object;

    return rest;
  }

  const replaceSchedule = (new_schedule = []) => {
    setScheduleList(prevState => new_schedule)
  }

  useEffect(() => {
    let api_date = toLocaleDate(Date().toLocaleString()).toJSON()
    const calendarCallback = (new_schedule = []) =>{
      replaceSchedule(new_schedule);
    }
    const search = query.get('search');
    dispatch(stageActions.getCalendarData(workspace_id, api_date, search, calendarCallback))
    initialLoaded.current = true
  }, []);

  useEffect(() => {
    if(updated_content_group){
      if (updated_content_group.id === labelDrpdwnValues.content_group_id){
        setLabelDrpdwnValues((prevState) => ({ ...prevState, labels: updated_content_group?.labels }))
      }
    }

  }, [updated_content_group])

  useEffect(() => {
    if(workspace_view === 'calendar'){
      const calendarInstance = calendarRef.current.getInstance();

      calendarInstance.setDate(date);
    }
  }, [workspace_view]);

  useEffect(() => {
    if(updated_content_group){
      let dupList = scheduleList;
      let index = dupList.findIndex((element) => element.id == updated_content_group.id);

      if ( index !== -1){
        dupList[index] = updated_content_group

        setScheduleList(prevState => dupList)
        closeOverlayPopup();
      }
    }
  }, [updated_content_group]);

  useEffect(() => {
    let api_date = toLocaleDate(date).toJSON()

    const calendarCallback = (new_schedule = []) => {
      addSchedule(new_schedule)
    }

    dispatch(stageActions.getCalendarData(workspace_id, api_date, currentSearchText, calendarCallback))
  }, [date, created_content_group, destroyed_content_group]);

  useEffect(() => {
    if(isVisible && initialLoaded.current && loadTracker){
      let api_date = toLocaleDate(date).toJSON();

      const calendarCallback = (new_schedule = []) => {
        addSchedule(new_schedule)
      }

      dispatch(stageActions.getCalendarData(workspace_id, api_date, currentSearchText, calendarCallback));
    }
  }, [loadTracker]);

  useEffect(() => {
    initialLoaded.current = false
    if (isVisible) {
      let api_date = toLocaleDate(date).toJSON()
      const calendarCallback = (new_schedule = []) =>{
        replaceSchedule(new_schedule)
      }
      const search = query.get('search');
      dispatch(stageActions.getCalendarData(workspace_id, api_date, search, calendarCallback))
      initialLoaded.current = true
    }
  }, [currentSearchText]);


  useEffect(() => {
    if (isVisible && !initialLoaded.current) {
      let api_date = toLocaleDate(date).toJSON()

      const calendarCallback = (new_schedule = []) =>{
        replaceSchedule(new_schedule)
      }


      dispatch(stageActions.getCalendarData(workspace_id, api_date, currentSearchText, calendarCallback))
      initialLoaded.current = true
    }
  }, [isVisible]);

  const handleClickRenderButton = () => {
    const calendarInstance = calendarRef.current.getInstance();

    calendarInstance.render();
  }

  const handleClickTodayButton = () => {
    const calendarInstance = calendarRef.current.getInstance();

    calendarInstance.today();
    setDate(calendarInstance.getDate().d.toLocaleString());
  }

  const handleClickPrevButton = () => {
    const calendarInstance = calendarRef.current.getInstance();

    calendarInstance.prev();
    setDate(calendarInstance.getDate().d.toLocaleString());
  }

  const handleClickNextButton = () => {
    const calendarInstance = calendarRef.current.getInstance();

    calendarInstance.next();
    setDate(calendarInstance.getDate().d.toLocaleString());
  }

  const onClickSchedule = (e) => {
    e.nativeEvent.preventDefault();
    e.nativeEvent.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    const { target, clientX, clientY } = e.nativeEvent
    const { calendarId, id, raw } = e.event;
    //const el = calendarRef.current.calendarInst.getElement(id, calendarId);

    if (target.classList.contains('content-label__close-button') && can_access_edit_label){
      let target_id = target.id
      let label_id = target_id.split('-')[1];

      let requestParams = { label_id: label_id, assigned: false }
      let callback = () => {}
      let content_popup_notification = undefined;

      dispatch(content_groupActions.assignContentLabelToContentGroup(workspace_id, id, requestParams, content_popup_notification, callback))

    } else if (target.classList.contains('content-label') && can_access_edit_label){
      let target_id = target.id
      let label_id = target_id.split('-')[1];

      handleOpenLabelDrpdwn(target, id, raw.labels, labelDrpdwnContainerRef, showLabelDrpdwn, setShowLabelDrpdwn)
    } else if (target.classList.contains('content-label__text') ) {
      handleOpenContentGroupPopUp(id);
    }
  };

  const closeOverlayPopup = () => {
    const popup_overlay = document.getElementsByClassName('toastui-calendar-popup-overlay')[0]

    if(popup_overlay){
      popup_overlay.click();
    }
  }

  const mapSchedule = (items) => {
    return items?.map(item => (
      item.due_date && {
        id: item.id,
        title: item.name,
        raw: {
          labels: item.labels
        },
        category: 'time',
        dueDateClass: '',
        calendarId: 1,
        start: item.due_date && dateTimeFormatter(item.due_date, 'input'),
        end: item.due_date && dateTimeFormatter(item.due_date, 'input'),
        isVisible: item.due_date && eventFilter(dateTimeFormatter(item.due_date, 'input')),
        body: `
          <div class="row">
            <div classcol-sm-2">Stage: </div>
            <div class="col-sm-10">${item.stage_name}</div>
          </div>
          <a href="${workspaceBaseUrl}/${workspace_id}/content_groups/${item.id}/contents">View <i class="fa fa-external-link" aria-hidden="true"></i></a>`,
        isReadOnly: false
      }
    )).filter(item => !!item);
  }

  let exact_match = false;

  let labels = useSelector(state => {
    if(state.work_space.currentWorkSpace) {
      return state.work_space.currentWorkSpace.labels.map(item =>({
        id: item.id,
        name: item.name,
        bgColor: "#"+getColor(item.color_hex),
        borderColor: "#"+getColor(item.color_hex)
      }));
    }
    return [];
  });

  const themeConfig = {
    common:{
      holiday:{
        color: 'rgb(51, 51, 51)',
      }
    },
    month:{
      dayName: {
        borderLeft: '0',
        backgroundColor: '#f4f1ec'
      },
      holidayExceptThisMonth: {
        color: 'rgb(51, 51, 51)'
      },
      dayExceptThisMonth: {
        color: 'rgb(51, 51, 51)'
      }
    }
  }

  const onBeforeUpdateSchedule = function(event) {
    if(can_access_move_content_calendar) {
      var schedule  = event.event;
      var changes = event.changes;

      if(schedule.end.d.d.toString() === changes.end.d.d.toString()) return;

      updateContentGroup(schedule, changes);
    }else {
      dispatch(alertActions.error("You are not authorized to access this page"));
    }
  }

  const [shopContentForm, setShopContentForm] = useState(false)
  const [dueDate, setDueDate] = useState(false)
  const onBeforeCreateSchedule = function(event) {
    if (can_access_content) {
      dispatch(change("content_group_form", "due_date", event.end))
      setDueDate(event.end)
      setShopContentForm(true)
    }
  }

  const handleCloseForm = () => {
    setShopContentForm(false)
    const calendarInstance = calendarRef.current.getInstance();

    dispatch(reset('content_group_form'));
    if(calendarInstance){
      calendarInstance.clearGridSelections();
    }
  }

  const handleSeeMoreEvents = (date) =>{
    const { top, height, left } = date.target.getBoundingClientRect();

    const { innerWidth, innerHeight } = window;
    const padding = top - innerHeight;

    if(top < 0){
      return date.target.style.top = '51px';
    }

    if(innerHeight <= top + height){
      const beta_sum = top - (height + padding);
      if(beta_sum >0) {
        return date.target.style.top = beta_sum + 'px';
      } else{
        return date.target.style.top = top - (height) + 'px';
      }
    }
  }

  const showResults = (values) => {
    dispatch(content_groupActions.createContentGroup(values, workspace_id, handleCloseForm));
  }

  const updateContentGroup = (schedule, changes) => {
    let contentGroupId = schedule.id;

    let values = { content_group: { dueDate: "" + changes.end.d } };
    dispatch(content_groupActions.updateContentGroup(values, contentGroupId, workspace_id, handleClickRenderButton));
  };

  const eventFilter= (due_date)=>{
    return true;

    //const current_month = new Date(date).getMonth();
    //const event_date = new Date(due_date).getMonth();
    //return event_date === current_month;
  }

  return (
      <div className="d-flex flex-column" style={{ height: "100%" }} ref={calendarTabRef}>
        <div className="calendar-button_group layout__adaptive-wrapper">
          <Row>
            <Col md="7" lg="9" className={"d-flex align-items-center"}>
              <h2 id="renderRange" className="render-range calendar__month mb-0">{ dateTimeFormatter(date, 'calendar_month')}</h2>
            </Col>
            <Col md="5" lg="3" className={"d-flex justify-content-end"}>
              <Button variant="" onClick={handleClickTodayButton} className="mx-1 btn-today">This Month</Button>
              <Button variant="" onClick={handleClickPrevButton} className="mx-1"><i className="fa fa-angle-left" aria-hidden="true"></i></Button>
              <Button variant="" onClick={handleClickNextButton} className="mx-1"><i className="fa fa-angle-right" aria-hidden="true"></i></Button>
            </Col>
          </Row>
        </div>

        {
          workspace_view === 'calendar' &&
          <div
            className="calendar-wrapper layout__adaptive-wrapper pt-0"
            style={{ overflowY: "auto" }}
          >
            <Calendar
              useDetailPopup={false}
              onSelectDateTime={onBeforeCreateSchedule}
              onBeforeUpdateEvent={onBeforeUpdateSchedule}
              onClickMoreEventsBtn={handleSeeMoreEvents}
              month={{
                visibleEventCount: 1
              }}
              usageStatistics={false}
              ref={calendarRef}
              height="700px"
              events={ mapSchedule(scheduleList) }
              view= {'month'}
              gridSelection={{
                enableClick: can_access_content,
                enableDblClick: false
              }}
              taskView={true}
              theme={themeConfig}
              eventView={true}
              onClickEvent={onClickSchedule}
              template= {{
                time(schedule) {
                  if (schedule.raw && schedule.raw.labels) {
                    let projectLabels = schedule.raw.labels.map((label, index) => (
                      renderToString(
                        <ContentLabel
                          id={label.id}
                          custom_close_button_id_text={custom_close_button_id_text}
                          editable={can_access_edit_label}
                          name={label.name}
                          color_hex={label.color_hex}
                          custom_close_button_bg_color={"#E9E8F5"}
                          sharesPopup
                        />
                      )
                    )).join('');

                    return "<div class='my-1 content-label__text'>" + schedule.title + "</div><div class='calendar__project-labels'>" + projectLabels + "</div>";
                  } else {
                    return "<span>" + schedule.title + "</span>";
                  }
                },
                allday(schedule) {
                  return "<span>foo</span>"; //JSON.stringify(schedule);
                },
                popupDetailDate(isAllDay, start, end) {
                  var end_date = new Date(end).toISOString();
                  return ("Due date: "+ dateTimeFormatter(end_date, 'calendar_date_time'));
                },
              }}
            />
          </div>
        }


        { shopContentForm &&
          <ContentGroupContent
            stages={stages}
            onSubmit={showResults}
            dueDateInitialize={dueDate && dueDate}
            handleCloseForm={handleCloseForm}
          />
        }

        <nav
          className={`content-label-popup vertical-scroll_thin ${ showLabelDrpdwn ? "active" : "inactive"}`}
          ref={labelDrpdwnContainerRef}
          style={drpdwnPosition}
        >
          <ContentLabelPopup
            labels={ labelDrpdwnValues.labels }
            workspaceId={ workspace_id }
            contentGroupId={ labelDrpdwnValues.content_group_id }
            handleClose={ () =>  handleCloseLabelDrpdwn() }
            can_access_edit_label={can_access_edit_label}
            automaticClose={true}
            show={showLabelDrpdwn}
          />
        </nav>
      </div>
  );
}


const mapStateToProps = (state) => {
  return {
    content_group_loading: state.content_group.content_group_loading,
    loading: state.work_space.loading,
    created_content_group: state.content_group.created_content_group,
    updated_content_group: state.content_group.updated_content_group,
    destroyed_content_group: state.content_group.destroyed_content_group,
    schedule: state.stage.schedule,
    currentSearchText: state.search_history.current_search_text,
    workspace_view: state.work_space.workspaceView
  };
};

export default connect(mapStateToProps, {  })(WorkspaceCalendar);
