import React, { useEffect, useState, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { userActions, content_groupActions, work_spaceActions, stageActions, assigned_userActions, contentLabelActions } from "../../../actions";
import { Button, Row, Col, Table, Tabs, Tab } from "react-bootstrap";
import { useCallback } from "react";
import { adminBaseURL } from "../../../utils/commonUtils";
import { Popup, LoadSpinner, SortContentGroup } from '../../../components';
import WorkspaceCalendar  from "../WorkSpacePage/calendar";
import moment from 'moment';
import ContentGroupNew  from "../ContentGroupPage/new";
import { useMediaQuery } from 'react-responsive';
import Board from "./Kanban/board";
import ContentGroupsTables from "./content_groups_tables";
import '../../stylesheets/workspace_page-show.scss';
import ContentList from "../ContentPage/list";
import AssignedUserNew from "../AssignedUserPage/new";
import StagePageNew  from '../StagePage/new';
import { WorkspaceShowContext } from "../../../contexts/WorkspaceShowContext";
import { WorkspaceBarContext } from "../../../contexts/WorkspaceBarContext";
import WorkspaceBar from '../LayoutPage/workspaceBar';
import ContentLabelPopup from '../ContentLabelPopup/list';
import { useDetectOutsideClick } from "../../../utils/useDetectOutsideClick";
import { getDropdownPosition } from "../../../utils";

function WorkSpaceShow(props) {
  let { workspace_id, content_group_id, mode, content_slug, organization_id } = useParams();

  if (content_slug){
    mode = 'list';
    content_group_id = content_slug.split('-')[0];
  }

  const {
    currentWorkSpace,
    currentContentGroup,
    can_invite_collaborator,
    workspace_view,
    can_access_stage,
    can_access_sort_stage,
    can_access_move_stage_board,
    can_access_content,
    can_access_move_content_calendar,
    can_access_move_content_board,
    can_access_edit_title,
    can_access_approval_request,
    can_access_assigned_user,
    can_access_show_content_details,
    can_access_move_content_list,
    can_access_add_change_date,
    can_access_approval_dots,
    can_access_edit_label,
    contentGroupPopUp,
    setContentGroupPopUp,
    currentSearchText
  } = props
  const dispatch = useDispatch();
  const work_space = useSelector(state => state.work_space.currentWorkSpace);
  const workspaceBaseUrl = adminBaseURL("workspaces");
  const isMobileScreen = useMediaQuery({ query: '(max-width: 991px)' });
  const [key, setKey] = useState("list");
  const Interval_MS = 5000;
  const inactiveSearchText = currentSearchText === '' || currentSearchText === null || currentSearchText === undefined;
  const workspaceBarRef = useRef(null);
  const [offsetHeight, setOffsetHeight] = useState(workspaceBarRef.current? workspaceBarRef.current?.offsetHeight : 0)
  const [loadTracker, setLoadTracker] = useState(false);
  const workspaceBarContexts = {
    workspaceBarRef: workspaceBarRef
  }
  const [ pause_live_update, setPauseLiveUpdate ] = useState(false);
  const query = new URLSearchParams(props.location.search);

  const handleCloseContentGroupPopUp = () => {
    if(content_slug){

    }
    setContentGroupPopUp({id: null, show: false})
  }

  const handleOpenContentGroupPopUp = (id) => {
    setContentGroupPopUp({id: id, show: true})
  }

  /*Assigned User Popup*/
  const [popupAssignedUser, setPopupAssignedUser] = useState({
    id: null,
    show: false,
    fromList: false
  });

  const handleCloseAssignedUserPopup = () =>{

    setPopupAssignedUser({id: null, show: false, fromList: false});
  }

  const handleOpenAssignedUserPopup = (id, fromList) =>{
    dispatch(assigned_userActions.newAssignedUser(workspace_id, id, fromList, setPopupAssignedUser));
  }

  useEffect(() => {
    if(workspace_id){
      dispatch(work_spaceActions.showWorkSpace(workspace_id));
    }else {
    }

    const noReload = true

    dispatch(work_spaceActions.setWorkspaceView(workspace_id, mode, noReload));

    if(content_group_id){
      handleOpenContentGroupPopUp(content_group_id)
    }

    if (workspace_view == "calendar") {
      return;
    } else if (workspace_view == "list") {
      //dispatch(work_spaceActions.showWorkSpace(workspace_id, true));
    }
  }, []);

  useEffect(() => {
    dispatch(work_spaceActions.setWorkspaceView(workspace_id, mode, true))
  }, [mode]);

  useEffect(() => {
    if(workspaceBarRef.current?.offsetHeight !== 0){
      setOffsetHeight((prevState) => workspaceBarRef.current?.offsetHeight)
    }
  }, [workspaceBarRef?.current?.offsetHeight]);

  /*
    Popup New Form Modal
    Content Group
  */
  const [popupNewForm, setPopUpNewForm] = useState({
    show: false,
    stage_id: null
  });;

  const handleCloseNewForm = () => {
    setPopUpNewForm({show: false, stage_id: null });
  }

  const handleShowNewForm = (stage_id) => {
    dispatch(content_groupActions.newContentGroup(workspace_id, setPopUpNewForm, stage_id));
  }

  /*
    Popup New Modal
    New Stage
  */
  const [showNewStageFormPopup, setNewStageFormPopup] = useState(false);

  const handleCloseNewStageFormForm = () => {
    setNewStageFormPopup(false);
  }

  /*
    Calendar
  */

  const getColor =(color) => {
    if (color){
      var rgb = color.replace('#', '');

      return rgb;
    } else {
      return null
    }
  }

  const resetStage = (stage) => {
    let rowDup = rows;

    let current_stage_index = rowDup.findIndex(r => r.id === stage.id)

    if (current_stage_index !== -1){
      let updated_stage = rowDup[current_stage_index];
      updated_stage.page = 1
      setRows((prevState) => [
                                ...prevState.filter(s => s.id !== stage.id),
                                updated_stage
                             ].sort((x, y) => x.index > y.index ? 1:-1))
    }
  }

  const [date, setDate] = useState(Date().toLocaleString());;


  const handleInlineEdit = (handleOnBlur, values, content_group_id, setIsDisabled) => {
    const content_popup_notification = false;
    dispatch(content_groupActions.inlineEditContentGroup(handleOnBlur, values, content_group_id, workspace_id, content_popup_notification, setIsDisabled));
    dispatch(content_groupActions.replaceContentImageLazeLoading(workspace_id, [content_group_id]));
  }

  /*
    Popup Edit Form Modal
  */

  const handleSortByDueDateStage = (id, sort_type) => {
    dispatch(stageActions.sortByDueDate(workspace_id, id, sort_type));
  }

  const [deleteStagePopup, setDeleteStagePopup] = useState({
    show: false,
    id: null,
  })

  const handleCloseDeleteStagePopup = () => {
    setDeleteStagePopup({ show: false, id: null });
  }

  const handleShowDeleteStagePopup = (id) => {
    setDeleteStagePopup({ show: true, id: id });
  }

  const handleDeleteStage = () => {
    dispatch(stageActions.destroyStage(workspace_id, deleteStagePopup.id))
    handleCloseDeleteStagePopup()
  }

  const [rows, setRows] = useState([]);

  const getStages = (obj) => {
    return obj.stages.map(stage => ({
        id: stage.id,
        name: stage.name,
        workspace_id: stage.workspace_id,
        updated_at: stage.updated_at,
        index: stage.index,
        created_at: stage.created_at,
        require_approval_from_client: stage.require_approval_from_client,
        visible_to_client: stage.visible_to_client,
        enable_revision: stage.enable_revision,
        enable_timestamp: stage.enable_timestamp,
        items: getCurrentContentGroups(stage.id),
        page: getCurrentPageOfStage(stage.id),
        total_pages: getCurrentTotalPage(stage.id),
        has_next_page: getCurrentHasNextPage(stage.id)
      })
    )
  }

  const getCurrentPageOfStage = (stage_id) =>{
    if (rows?.length === 0) return 1;

    let current_stage_index = rows.findIndex(r => r.id === stage_id)

    if (current_stage_index === -1) return 1;

    return rows[current_stage_index].page;
  }

  const getCurrentContentGroups = (stage_id) => {
    if (rows?.length === 0) return [];

    let current_stage_index = rows.findIndex(r => r.id === stage_id)

    if (current_stage_index === -1) return [];

    return rows[current_stage_index].items;
  }

  const getCurrentTotalPage = (stage_id) => {
    if (rows?.length === 0) return 1;

    let current_stage_index = rows.findIndex(r => r.id === stage_id)

    if (current_stage_index === -1) return 1;

    return rows[current_stage_index].total_pages;
  }

  const getCurrentHasNextPage = (stage_id) => {
    if (rows?.length === 0) return false;

    let current_stage_index = rows.findIndex(r => r.id === stage_id)

    if (current_stage_index === -1) return false;

    return rows[current_stage_index].has_next_page;
  }

  const updateRecords = (newProps) =>{
    let updatedStageArr = getStages(newProps);
    let updatedrowsFromBackend = updatedStageArr
    setRows(updatedrowsFromBackend);
  }

  const nextPage = (stage) => {
    setPauseLiveUpdate(true);

    let rowDup = rows;

    let current_stage_index = rowDup.findIndex(r => r.id === stage.id)

    if (current_stage_index !== -1){
      let updated_stage = rowDup[current_stage_index];
      let current_page = updated_stage.page;
      updated_stage.page = current_page + 1;
      setRows((prevState) => [
                                ...prevState.filter(s => s.id !== stage.id),
                                updated_stage
                             ].sort((x, y) => x.index > y.index ? 1:-1))
    }

    setPauseLiveUpdate(false);
  }

  const addContentGroupToStage = (stage, content_groups, total_pages, has_next_page, reset_content_group) => {
    let rowDup = rows;

    let current_stage_index = rowDup.findIndex(r => r.id === stage.id)

    if (current_stage_index !== -1){
      let filtered_content_groups  = content_groups?.filter(c => c.stage_id === stage.id)
      let new_content_group_ids = filtered_content_groups.map((c) => c.id);
      let updated_stage = rowDup[current_stage_index];
      let old_content_groups = updated_stage.items;

      if (reset_content_group){
        if (inactiveSearchText){
          updated_stage.items = SortContentGroup(filtered_content_groups)
        } else {
          updated_stage.items = SortContentGroup(filtered_content_groups)
          updated_stage.page = 1;
        }
      } else {
        if (props.updated_content_group) {
          if (props.updated_content_group.stage_id !== stage.id) {
            old_content_groups  = old_content_groups.filter(c => c.id !== props.updated_content_group.id)
          } else {
            old_content_groups  = [...old_content_groups.filter(c => c.id !== props.updated_content_group.id), props.updated_content_group]
          }
        }

        updated_stage.items = SortContentGroup([...old_content_groups.filter(c => !new_content_group_ids.includes(c.id)), ...filtered_content_groups])
      }
      updated_stage.total_pages = total_pages
      updated_stage.has_next_page = has_next_page

      setRows((prevState) => [...prevState.filter(s => s.id !== stage.id), updated_stage].sort((x, y) => x.index > y.index ? 1:-1))
    }
  }

  const refreshContentGroupToStage = (stage, content_groups) => {
    let rowDup = rows;

    let current_stage_index = rowDup.findIndex(r => r.id === stage.id)

    if (current_stage_index !== -1){
      let filtered_content_groups  = content_groups.filter(c => c.stage_id === stage.id)
      let updated_stage = rowDup[current_stage_index];

      updated_stage.items = SortContentGroup(filtered_content_groups)
      setRows((prevState) => [...prevState.filter(s => s.id !== stage.id), updated_stage].sort((x, y) => x.index > y.index ? 1:-1))
    }
  }

  const removeContentGroupToStage = (stage, content_group) => {
    let rowDup = rows;

    let current_stage_index = rowDup.findIndex(r => r.id === stage.id)

    if (current_stage_index !== -1){
      let updated_stage = rowDup[current_stage_index];
      let old_content_groups = updated_stage.items;
      updated_stage.items = SortContentGroup([...old_content_groups.filter(c => c.id !== content_group.id)])

      setRows((prevState) => [...prevState.filter(s => s.id !== stage.id), updated_stage].sort((x, y) => x.index > y.index ? 1:-1))
    }
  }

  const replaceContentGroupToStage = (stage, content_group) => {
    let rowDup = rows;

    let current_stage_index = rowDup.findIndex(r => r.id === stage.id)

    if (current_stage_index !== -1){
      let updated_stage = rowDup[current_stage_index];
      let old_content_groups = updated_stage.items;
      updated_stage.items = SortContentGroup([...old_content_groups.filter(c => c.id !== content_group.id), content_group])

      setRows((prevState) => [...prevState.filter(s => s.id !== stage.id), updated_stage].sort((x, y) => x.index > y.index ? 1:-1))
    }
  }

  let labelDrpdwnContainerRef = useRef();
  let [showLabelDrpdwn, setShowLabelDrpdwn] = useDetectOutsideClick(labelDrpdwnContainerRef, false);
  let [drpdwnPosition, setDrpdwnPosition] = useState({
    top: 0,
    left: 0
  });
  let [labelDrpdwnValues, setLabelDrpdwnValues] = useState({
    content_group_id: null,
    labels: []
  });

  const handleCloseLabelDrpdwn = () => {
    setShowLabelDrpdwn((prevState) => false);
    setLabelDrpdwnValues({
      content_group_id: null,
      labels: []
    })
  };

  const handleOpenLabelDrpdwn = (target, content_group_id, labels, dropdownRef, showDropdown, setShowDropdown) => {
    setShowDropdown(false);

    let styles = getDropdownPosition({ target: target, top_padding: 0, dropdown: dropdownRef?.current })

    setDrpdwnPosition(styles)

    setLabelDrpdwnValues({
      content_group_id: content_group_id,
      labels: labels
    });

    setShowDropdown(true);
  };

  useEffect(() => {
    if (pause_live_update || currentContentGroup || loadTracker){
      return null;
    }
    console.log("Workspace useEffect");

    const interval = setInterval(() => {

      console.log("Workspace setInterval");

      const successCallback = () => {
        setLoadTracker(true)

        console.log("refreshShowWorkSpace successCallback");

        const timeout = setTimeout(() => {
         setLoadTracker(false)

         console.log("refreshShowWorkSpace setTimeout")
        }, 5000)

        return () => clearTimeout(timeout)
      }

      dispatch(work_spaceActions.refreshShowWorkSpace(workspace_id, successCallback));

   }, Interval_MS);

   return () => clearInterval(interval);
 }, [pause_live_update, currentContentGroup, loadTracker]);


  let workspaceShowContext = {
    workspace_id: workspace_id,
    can_access_stage: can_access_stage,
    can_access_content: can_access_content,
    can_access_sort_stage: can_access_sort_stage,
    rows: rows,
    setRows: setRows,
    getStages: getStages,
    updateRecords: updateRecords,
    nextPage: nextPage,
    updated_at: work_space?.updated_at,
    addContentGroupToStage: addContentGroupToStage,
    resetStage: resetStage,
    removeContentGroupToStage: removeContentGroupToStage,
    replaceContentGroupToStage: replaceContentGroupToStage,
    handleSortByDueDateStage: handleSortByDueDateStage,
    handleOpenContentGroupPopUp: handleOpenContentGroupPopUp,
    handleShowDeleteStagePopup: handleShowDeleteStagePopup,
    handleOpenAssignedUserPopup: handleOpenAssignedUserPopup,
    handleOpenLabelDrpdwn: handleOpenLabelDrpdwn,
    setLabelDrpdwnValues: setLabelDrpdwnValues,
    labelDrpdwnValues: labelDrpdwnValues,
    drpdwnPosition: drpdwnPosition,
    showLabelDrpdwn: showLabelDrpdwn,
    setShowLabelDrpdwn: setShowLabelDrpdwn,
    labelDrpdwnContainerRef: labelDrpdwnContainerRef,
    refreshContentGroupToStage: refreshContentGroupToStage,
    loadTracker: loadTracker,
    query: query
  }

  return (
    <WorkspaceBarContext.Provider value={workspaceBarContexts}>
      <WorkspaceBar contentGroupPopUp={contentGroupPopUp} setContentGroupPopUp={setContentGroupPopUp}/>

      <div className="" style={{ height: `calc(100% - ${offsetHeight}px)` }}>
        {props.loading && <LoadSpinner />}
        {props.assigned_user_loading && <LoadSpinner />}
        {props.content_loading && <LoadSpinner />}

        {
          work_space &&
          <WorkspaceShowContext.Provider value={workspaceShowContext}>

            <div className={`${workspace_view === "list" ? 'h-100' : 'd-none' }`}>
              <ContentGroupsTables
                stages={work_space.stages}
                workspace_id={workspace_id}
                content_groups={work_space.content_groups}
                setNewStageFormPopup={setNewStageFormPopup}
                can_crud_content_group={work_space.can_crud_content_group }
                can_access_edit_title={can_access_edit_title}
                can_access_assigned_user={can_access_assigned_user}
                can_access_show_content_details={can_access_show_content_details}
                can_access_move_content_list={can_access_move_content_list}
                can_access_add_change_date={can_access_add_change_date}
                handleInlineEdit={handleInlineEdit}
                can_access_approval_dots={can_access_approval_dots}
                can_access_edit_label={can_access_edit_label}
                can_access_move_stage_board={can_access_move_stage_board}
              />
            </div>

            <div className={`${workspace_view === "board" ? 'h-100 position' : 'd-none' }`}>
              <Board
                stages={work_space.stages}
                content_groups={work_space.content_groups}
                workspace_id={ workspace_id }
                organization_id={ organization_id }
                can_crud_content_group={work_space.can_crud_content_group }
                setNewStageFormPopup={setNewStageFormPopup}
                can_access_move_content_board={can_access_move_content_board}
                can_access_edit_title={can_access_edit_title}
                can_access_edit_label={can_access_edit_label}
                can_access_move_stage_board={can_access_move_stage_board}
                can_access_show_content_details={can_access_show_content_details}
                can_access_approval_dots={can_access_approval_dots}
              />
            </div>

            <div className={`${workspace_view === "calendar" ? 'h-100' : 'd-none' }`} style={{overflowY: 'auto'}}>
              <WorkspaceCalendar
                workspace_id={ workspace_id }
                stages={work_space.stages}
                date ={ date }
                can_access_edit_label={can_access_edit_label}
                can_access_move_content_calendar={can_access_move_content_calendar}
              />
            </div>

            {/* Assigned User*/}
            {
              popupAssignedUser.show &&
              <AssignedUserNew
                handleCloseAssignedUserPopup={handleCloseAssignedUserPopup}
                content_group_id={popupAssignedUser.id}
                fromList={popupAssignedUser.fromList}
              />
            }

            {
              contentGroupPopUp.show &&
              <ContentList
                content_group_id={contentGroupPopUp.id}
                contentGroupPopUp={contentGroupPopUp}
                setContentGroupPopUp={setContentGroupPopUp}
                workspace_id={workspace_id}
                handleCloseContentGroupPopUp={handleCloseContentGroupPopUp}
                updated_at={work_space.updated_at}
                handleInlineEdit={handleInlineEdit}
                can_access_content={can_access_content}
                can_access_edit_title={can_access_edit_title}
              />
            }
            {
              popupNewForm.show &&
              <ContentGroupNew
                selected_stage_id={popupNewForm.stage_id}
                handleCloseNewForm={handleCloseNewForm}
                handleOpenContentGroupPopUp={handleOpenContentGroupPopUp}
              />
            }

            {
              showNewStageFormPopup &&
              <StagePageNew
                fromKanban={true}
                handleCloseNewForm={handleCloseNewStageFormForm}
                can_access_approval_request={can_access_approval_request}
              />
            }
          </WorkspaceShowContext.Provider>
        }

        {deleteStagePopup.show &&
          <Popup
            handleClose={handleCloseDeleteStagePopup}
            handleDelete={handleDeleteStage}
          />
        }

        {/*
          Content Label Pop up
           - used at the board at the moment due to conflict in overflow
        */}
        <nav
          className={`content-label-popup vertical-scroll_thin ${ showLabelDrpdwn ? "active" : "inactive"}`}
          ref={labelDrpdwnContainerRef}
          style={drpdwnPosition}
        >
          <ContentLabelPopup
            labels={ labelDrpdwnValues.labels }
            workspaceId={ workspace_id }
            contentGroupId={ labelDrpdwnValues.content_group_id }
            handleClose={ () =>  handleCloseLabelDrpdwn() }
            can_access_edit_label={can_access_edit_label}
          />
        </nav>
      </div>
    </WorkspaceBarContext.Provider>
  );
}


const mapStateToProps = (state) => {
  return {
    loading: state.work_space.loading,
    can_invite_collaborator: state.work_space.can_invite_collaborator,
    currentContentGroup: state.content.currentContentGroup,
    currentWorkSpace: state.work_space.currentWorkSpace,
    content_group_loading: state.content_group.content_group_loading,
    content_loading: state.content.content_loading,
    assigned_user_loading: state.assigned_user.assigned_user_loading,
    updated_content_group: state.content_group.updated_content_group,
    workspace_view: state.work_space.workspaceView,
    can_access_stage: state.work_space.can_access_stage,
    can_access_sort_stage: state.work_space.can_access_sort_stage,
    can_access_content: state.work_space.can_access_content,
    can_access_move_content_calendar: state.work_space.can_access_move_content_calendar,
    can_access_move_content_board: state.work_space.can_access_move_content_board,
    can_access_edit_title: state.work_space.can_access_edit_title,
    can_access_approval_request: state.work_space.can_access_approval_request,
    can_access_move_stage_board: state.work_space.can_access_move_stage_board,
    can_access_assigned_user: state.work_space.can_access_assigned_user,
    can_access_show_content_details: state.work_space.can_access_show_content_details,
    can_access_move_content_list: state.work_space.can_access_move_content_list,
    can_access_add_change_date: state.work_space.can_access_add_change_date,
    can_access_approval_dots: state.work_space.can_access_approval_dots,
    can_access_edit_label: state.work_space.can_access_edit_label,
    currentSearchText: state.search_history.current_search_text
  };
};

export default connect(mapStateToProps, { userActions })(WorkSpaceShow);
