import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { userActions, alertActions } from '../../../actions';
import { validation } from '../../../shared/form-validations';
import { textInput, PasswordInput } from '../../../shared/form-elements'
import { reduxForm, Field, reset } from "redux-form";
import { Button } from 'react-bootstrap';
import { LoginHeader } from '../LayoutPage/loginHeader';
import { Image, isLoggedIn } from '../../../utils';
import { history } from '../../../helpers';
import { userConstants } from '../../../constants';


function PasswordEdit(props) {

  const { handleSubmit} = props
  const dispatch = useDispatch();
  const location = useLocation();
  const query = new URLSearchParams(props.location.search);
  const reset_password_token = query.get('reset_password_token');

  useEffect(() => {
    if(isLoggedIn()){
      history.push('/');
      dispatch(alertActions.error(userConstants.LOGIN_ALREADY));
    }else{
      dispatch(userActions.logout());
    }
  }, []);

  const formSubmit = (values) => {
    const { from } = location.state || { from: { pathname: "/" } };
    values.reset_password_token = reset_password_token;
    dispatch(userActions.resetPassword(values, from));
  }

  return (
    <main className="login-page full_height row no-gutters">
      <div className="col-md-8 login-page__info d-sm-none d-md-block"
           style={{ backgroundImage: 'url("' + Image.Layout.LoginBackground.default + '")' }}/ >
      <div className="login-page__box d-flex justify-content-center align-items-center col-12 col-md-4">
        <div
          className="col-md-10"
        >
          <LoginHeader />

          <h3 className="font-weight-bold text-center mb-4">Change Password</h3>
          <form
            onSubmit={handleSubmit(formSubmit)}
            className="login-page__form"
          >

            <div className="mb-2">
              <Field name="password" type="password" component={PasswordInput} label="New Password"/>
            </div>

              <div className="mb-2">
                <Field name="password_confirmation" type="password" component={PasswordInput} label="Confirm New Password"/>
              </div>
              <div className="mb-3 mt-4">
                <Button variant="brand" type="submit" className="full-width">Change Password</Button>
              </div>
            </form>
          </div>
      </div>
    </main>
  );
}

PasswordEdit =  reduxForm({
  form: 'passwordEditForm',
  validate: validation,
  touchOnBlur: false
})(PasswordEdit);


const mapStateToProps = (state) => {
  return { password: "", password_confirmation: "" };
};

export default connect(mapStateToProps, { userActions })(PasswordEdit);
